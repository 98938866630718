<template>
  <div
    :style="getElementStyle"
    v-if="!loading && data.properties.filed_content != 'Hide'"
  >
  <div v-if="!isDefalutPos" style="display: flex; align-items: center;">
      <div style="white-space: nowrap;">
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >{{
          label||data.label
        }}</label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span
          v-if="data.properties.is_unique"
          style="color: red; font-weight: bold; margin-left: 1%"
        >
          <i class="el-icon-attract"></i>
        </span>
        <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
      </div>
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24" style="display: contents;">
        <!-- :picker-options="pickerOptions" -->

        <el-date-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          type="datetimerange"
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() "
          :placeholder="data.placeholder || data.label"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          popper-class="my-popover"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="onchange"
          :readonly="readonly()"
        ></el-date-picker>

        <el-date-picker
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          :size="profilePage ? 'mini' : ''"
          v-model="form[data.key]"
          type="datetimerange"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start date'"
          :end-placeholder="data.placeholder1|| 'End date'"
          popper-class="my-popover"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="onchange"
          :readonly="readonly()"
        >
        </el-date-picker>

        <!-- 
        <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="dates"
          type="datetimerange"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :picker-options="pickerOptions"
              range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                  popper-class="my-popover"
              :format="'MM-dd-yyyy HH:mm:ss'"   
            
              @change="onchange"
        >
        </el-date-picker>
       -->

        <!-- <el-date-picker
        v-if="isList"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]"
          :readonly="true"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker> -->

        <!-- <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker> -->

        <p
          v-if="
            isView &&
            form[this.data.key] &&
            data.properties.filed_content != 'Hide'
          "
          :style="getStyle"
        >
          <template v-for="item in form[this.data.key].slice(0, 1)">
            {{ item | moment("MM-DD-YYYY HH:mm:ss") }} (From ~ To)
          </template>

          <template v-for="item in form[this.data.key].slice(1, 2)">
            {{ item | moment("MM-DD-YYYY HH:mm:ss") }}
          </template>
        </p>

        <p v-else></p>
      </el-col>
  </div>

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        v-if="!data.properties.hideLabel"
      >
        <label
          for="text"
          v-if="hasLabel && !data.properties.hideLabel"
          :style="getLabelStyles"
        >{{
          label||data.label
        }}</label>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span
          v-if="data.properties.is_unique"
          style="color: red; font-weight: bold; margin-left: 1%"
        >
          <i class="el-icon-attract"></i></span>
          <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
      </el-col>
      <el-col v-else :span="showLabel  ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span v-if="hasLabel" style="color: white; display: inline-block; height: 20px; opacity: 0;" ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <!-- <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          title="Help"
          trigger="click"
          :content="data.description"
        >
          <i
            class="el-icon-info"
            slot="reference"
          ></i>
        </el-popover>
      </el-col> -->
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24"
        class="execute-view">
        <!-- :picker-options="pickerOptions" -->

        <el-date-picker
          v-if="isList && !isView && data.properties.filed_content != 'Hide'"
          type="datetimerange"
          :style="getStyle"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly() "
          :placeholder="data.placeholder || data.label"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          popper-class="my-popover"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="onchange"
          :readonly="readonly()"
        ></el-date-picker>

        <el-date-picker
          v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
          prefix-icon="el-icon-date"
          :size="profilePage ? 'mini' : ''"
          v-model="form[data.key]"
          type="datetimerange"
          :placeholder="data.placeholder || data.label"
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          range-separator="To"
          :start-placeholder="data.placeholder || 'Start date'"
          :end-placeholder="data.placeholder1|| 'End date'"
          popper-class="my-popover"
          :format="'MM-dd-yyyy HH:mm:ss'"
          @change="onchange"
          :readonly="readonly()"
          :class="{ 'highlighted-border': highlight }"
        >
        </el-date-picker>

        <!-- 
        <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="dates"
          type="datetimerange"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :picker-options="pickerOptions"
              range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                  popper-class="my-popover"
              :format="'MM-dd-yyyy HH:mm:ss'"   
            
              @change="onchange"
        >
        </el-date-picker>
       -->

        <!-- <el-date-picker
        v-if="isList"
          prefix-icon="el-icon-date"
          v-model="form[this.data.key]"
          :readonly="true"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker> -->

        <!-- <el-date-picker
        v-if="!isView  && !isList"
          prefix-icon="el-icon-date"
          v-model="form[data.key]"
          type="date"
            :placeholder="data.placeholder || data.label" 
          :style="getStyle"
          :disabled="isFromDocument ? false : checkReadonly()"
          :format="
            getDefaultDateFormat && getDefaultDateFormat.includes('Do') == false
              ? getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
              : 'MM-dd-yyyy'
          "
        >
        </el-date-picker> -->

        <p
          v-if="
            isView &&
            form[this.data.key] &&
            data.properties.filed_content != 'Hide'
          "
          :style="getStyle"
        >
          <template v-for="item in form[this.data.key].slice(0, 1)">
            {{ item | moment("MM-DD-YYYY HH:mm:ss") }} (From ~ To)
          </template>

          <template v-for="item in form[this.data.key].slice(1, 2)">
            {{ item | moment("MM-DD-YYYY HH:mm:ss") }}
          </template>
        </p>

        <p v-else></p>
      </el-col>
    </el-row>
    <!--<div class="form-group">
      <label for="text" v-if="hasLabel" :style="getLabelStyles">{{data.label}}</label>
      <el-popover
        v-if="data.description"
        placement="top-start"
        title="Help"
        trigger="click"
        :content="data.description"
      >
        <i class="el-icon-info" slot="reference"></i>
      </el-popover>

      <el-date-picker
        v-if="!isView"
        prefix-icon="el-icon-date"
        v-model="form[data.key]"
        type="date"
          :placeholder="data.placeholder || data.label" 
        :style="getStyle"
        :disabled="checkReadonly()"
      ></el-date-picker>

      <p v-if="isView" :style="getStyle">
        <template v-if="form[data.key]">{{form[data.key] | moment("YYYY-DD-MM")}}</template>
        <template v-else>-</template>
      </p>
    </div>-->
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

import { mapGetters } from "vuex";
import { bus } from "../../../main";
import * as moment from "moment";
export default {
  name: "templates-formComponentsExecute-DateExecute",
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "profilePage",
    "checkIsDisabled",
    "colorFields",
    "highlight",
    "fromEntityViews"
  ],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return `height:${this.data.height - 35}px; width: 100%`;
    },
    computedStyles() {
    const styles = {};
    styles.display = 'flex';
    if (this.data?.styles?.labelAlignments) {
      styles.justifyContent = this.data.styles.labelAlignments;
    }
    return styles;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    isDefalutPos() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      dates: [],
      isList: false,
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      pickerOptions: {
        shortcuts: [
          // {
          //   text: 'Today',
          //   onClick() {
          //     bus.$emit("date_range_entity",'TODAY')
          //   }
          // },
          {
            text: "24 Hours",
            onClick() {
              bus.$emit("date_range_entity", "TOMARROW");
            }
          },
          {
            text: "Weekly",
            onClick() {
              bus.$emit("date_range_entity", "WEEK");
            }
          },
          {
            text: "Bi-weekly",
            onClick() {
              bus.$emit("date_range_entity", "BIWEEKLY");
            }
          },
          {
            text: "Monthly",
            onClick() {
              bus.$emit("date_range_entity", "MONTHLY");
            }
          },
          {
            text: "Quarterly",
            onClick() {
              bus.$emit("date_range_entity", "QUARTERLY");
            }
          },
          {
            text: "Half-yearly",
            onClick() {
              bus.$emit("date_range_entity", "HALFYEARLY");
            }
          },
          {
            text: "Yearly",
            onClick() {
              bus.$emit("date_range_entity", "YEARLY");
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.loading = true;
   this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    this.loading = false;
  },
  methods: {
    defaultMethod(){
      if (this.data.default_value && this.form &&this.data.key && !this.form[this.data.key] && !this.data.apply_default_at_end) {
      //  this.form[this.data.key] = this.data.default_value;
      this.$set(this.form, this.data.key, this.data.default_value);
    }
    this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "DATE_TIME_RANGE") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
          this.form[
            this.data.key + "_from"
          ] = this.getGlobalVariableById.value[0];
          this.form[
            this.data.key + "_to"
          ] = this.getGlobalVariableById.value[1];
        }
      }
    },
    // checkReadonly() {
    //   if (this.data.styles && this.data.styles.labelStyle) {
    //     if (this.data.styles.labelStyle == "hide") {
    //       this.showLabel = false;
    //     }
    //     if (this.data.styles.labelStyle == "right") {
    //       this.isDefalutPos = false;
    //     }
    //   }
    //   return !this.fieldFilledByCurrentUser(this.data.filled_by);
    // },
    readonly() {
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_global_variable
      ) {
        return true;
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        //If it's an entity varaible, have to check access
        if (
          this.data.field_assignable === "read_only" &&
          this.data.is_entity_variable
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    onchange(value) {
      this.applyFormRules()
      this.form[this.data.key + "_from"] = value[0];
      this.form[this.data.key + "_to"] = value[1];
    }
  },
  created() {
    bus.$on("date_range_entity", data => {
      // if(data==='TODAY')
      // {
      //     const end = new Date()
      //     const start = new Date()
      //     this.formBuilder.settings.formbuilder_date_range_entity=[start, end]
      // }
      if (data === "TOMARROW") {
        const end = new Date();
        const start = new Date();
        var newDate = moment(end).add(1, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "WEEK") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(7, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "BIWEEKLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(15, "days");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "MONTHLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "QUARTERLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(3, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "HALFYEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(6, "months");
        this.form[this.data.key] = [start, newDate];
      }
      if (data === "YEARLY") {
        const end = new Date();
        const start = new Date();
        newDate = moment(end).add(1, "years");
        this.form[this.data.key] = [start, newDate];
      }
    });
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style lang="scss" scoped>
.highlighted-border {
  border-color: red !important;
}
.execute-view {
  font-weight: normal;
}
</style>